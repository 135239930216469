#widget-header-background {
    background-image: url("/public/img/clouds-transparent-animated.svg");
    background-repeat: none;
    background-position: bottom;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    opacity: 1;
    z-index: 1;
}

#widget-header-background::after {
    content: "";
    background-image: linear-gradient(rgb(2 132 199), rgb(38 32 96 / 0%));
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 2;
}