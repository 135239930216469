@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    a.text-link {
        @apply text-sky-700;
        @apply font-normal;
        @apply transition-colors;
    }
    a.text-link:hover {
        @apply text-sky-500;
    }
    .split-background {
        background-image: url("/public/img/clouds-transparent-animated.svg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100vh;
    }
}